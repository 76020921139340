import { numRound } from './number.js';

/**
 * 获取发布时间
 * @method getPubtime
 * @param  {Number}  t
 * @return {String}
 * @public
 */
export function getPubtime(num) {
    let str = '',
        time = +num;

    if (time < 60) {
        let currSecond = numRound(time) <= 0 ? 1 : numRound(time);
        
        str = currSecond + '秒前';
    } else if (time < 3600) {
        str = numRound(time / 60, 0) + '分钟前';
    } else if (time < 86400) {
        str = numRound(time / 3600, 0) + '小时前';
    } else {
        str = numRound(time / 86400, 0) + '天前';
    }

    return str;
}
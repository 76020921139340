function getById(str) {
    return document.getElementById(str);
}

function getByClass(cls, oParent) {
    let obj = (oParent || document);

    if (document.getElementsByClassName) {
        return obj.getElementsByClassName(cls);
    }

    let arr = [];
    let arrEl = obj.getElementsByTagName('*');

    let findSame = (arr, n) => {
        if (arr.indexOf) {
            return arr.indexOf(n);
        }

        for (let i=0; i<arr.length; i++) {
            if (arr[i] == n) {
                return i;
            }
        }

        return -1;
    };

    for (let i=0; i<arrEl.length; i++) {
        let aClass = arrEl[i].className.split(' ');

        if (findSame(aClass, cls) != -1) {
            arr.push(arrEl[i]);
        }
    }

}

function hasClass(cls, obj) {
    return obj.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

function addClass(cls, obj) {
    if (!hasClass(cls, obj)) {
        obj.className += ' ' + cls;
    }
}

function removeClass(cls, obj) {
    if (hasClass(cls, obj)) {
        const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');

        obj.className = obj.className.replace(reg, ' ');
    }
}

function toggleClass(cls, obj) {
    if (hasClass(obj, cls)) {
        removeClass(obj, cls);
    } else {
        addClass(obj, cls);
    }
}

export const dom = {
    getById: getById,
    getByClass: getByClass,
    hasClass: hasClass,
    addClass: addClass,
    removeClass: removeClass,
    toggleClass: toggleClass
};

/**
 * 平台校验
 * @method platform
 * @return {Object}
 * @private
 */
export const platform = (() => {
    let ua = navigator.userAgent.toLowerCase(),
        sys = {};

    (ua.indexOf('android') > -1 || ua.indexOf('adr') > -1) ? sys.android = true:
        (ua.match(/\(i[^;]+;( u;)? cpu.+mac os x/)) ? sys.ios = true : {};

    return sys;
})();

/**
 * 浏览器及版本
 * @method browser
 * @return {Object}
 * @public
 */
export const browser = (() => {
    let Sys = {},
        ua = navigator.userAgent.toLowerCase(),
        s;

    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        Sys.wx = true;
    } else if (ua.match(/rv:([\d.]+)\) like gecko/)) {
        Sys.ie = ua.match(/rv:([\d.]+)\) like gecko/)[1];
    } else if (ua.match(/msie ([\d.]+)/)) {
        Sys.ie = ua.match(/msie ([\d.]+)/)[1];
    } else if (ua.match(/firefox\/([\d.]+)/)) {
        Sys.firefox = ua.match(/firefox\/([\d.]+)/)[1];
    } else if (ua.match(/chrome\/([\d.]+)/)) {
        Sys.chrome = ua.match(/chrome\/([\d.]+)/)[1];
    } else if (ua.match(/opera.([\d.]+)/)) {
        Sys.opera = ua.match(/opera.([\d.]+)/)[1];
    } else if (ua.match(/version\/([\d.]+).*safari/)) {
        Sys.safari = ua.match(/version\/([\d.]+).*safari/)[1];
    }

    return Sys;
})();